<template>
  <layout :title="$route.name" :options="options">
      <div class="expande-horizontal wrap">
        <v-flex xs12 class="pa-6 pb-0">
          <v-list class="pa-0 ma-0" three-line style="border-radius: 6px;" dark>
            <v-list-item @click="goToUser">
              <v-list-item-avatar class="mr-3" size="60" style="border: 3px solid #fff;">
                <v-img
                    src="https://i.pinimg.com/originals/7c/c7/a6/7cc7a630624d20f7797cb4c8e93c09c1.png"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="fonte"> COMPRADOR: {{ getPurchase.user.name }} </v-list-item-title>
                <v-list-item-subtitle class="fonte"> {{ getPurchase.user.email }} </v-list-item-subtitle>
                <v-list-item-subtitle class="fonte"> {{ getPurchase.user.cpf }} </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>
            
            <v-list-item @click="() => {}" v-if="getPurchase.address">
              <v-list-item-avatar class="mr-3" size="60" style="border: 3px solid #333;">
                <v-icon>mdi-map-marker-circle</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="fonte"> ENDEREÇO: {{ getPurchase.address.address }}, Nº {{ getPurchase.address.house_number }}, CIDADE: {{ getPurchase.address.city }} - {{ getPurchase.address.uf }} </v-list-item-title>
                <v-list-item-subtitle class="fonte"> Bairro: {{ getPurchase.address.neigthborhood }} </v-list-item-subtitle>
                <v-list-item-subtitle class="fonte"> CEP: {{ getPurchase.address.postal_code }} </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>
            
            <v-list-item v-if="getPurchase.purchase_type === 'product'">
              <v-list-item-avatar class="mr-3" size="60" style="border: 3px solid #333;">
                <v-icon>mdi-cash</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="fonte orange--text"> FRETE: {{ getPurchase.frete_paid ? 'PAGO' : 'PENDENTE' }} </v-list-item-title>
                <v-list-item-subtitle class="fonte"> MODALIDADE DE PAGAMENTO DO FRETE: {{ getPurchase.paymentMethod.name }} </v-list-item-subtitle>
                <v-list-item-subtitle v-if="!getPurchase.frete_paid" class="fonte"> <v-btn :color="$theme.primary" @click="createConfirmAction({
                  opened: true,
                  icon: 'mdi-check',
                  color: 'green',
                  message: 'Confirma a aprovação do frete?',
                  action: 'approve_frete',
                  action_value: getPurchase
                })"> APROVAR FRETE </v-btn> </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-actions>
                
              </v-list-item-actions>
            </v-list-item>
          </v-list>
        </v-flex>
        <v-flex xs12 md4>
          <PurchaseCareer :fullscreen="true" :key="getPurchase._id" :purchase="getPurchase" v-if="getPurchase.purchase_type==='career'" />
          <PurchaseInvestment :fullscreen="true" :key="getPurchase._id" :purchase="getPurchase" v-if="getPurchase.purchase_type==='investment'" />
          <PurchaseProduct :fullscreen="true" :key="getPurchase._id" :purchase="getPurchase" v-if="getPurchase.purchase_type==='product'" />
        </v-flex>

        <v-flex xs12 md4 class="pa-3 pt-6">
          <div class="expande-horizontal bordered pa-3 column">
            <h1 class="fonte white--text text-center mb-3">Detalhes do Pagamento:</h1>
            <v-divider dark class="mb-3"></v-divider>
            <span v-if="getPurchase.item.token_price" class="grey--text fonte mt-6">PREÇO DA COMPRA EM TOKEN:</span>
            <span v-if="getPurchase.item.token_price" class="fonte green--text font-weight-light">{{ getPurchase.item.token_price }}</span>
            
            <span class="grey--text fonte mt-6">PREÇO DA COMPRA EM REAIS:</span>
            <span class="fonte green--text font-weight-light">{{ getPurchase.item.price }}</span>
            
            <span class="grey--text fonte mt-6">MÉTODO DE PAGAMENTO:</span>
            <span class="fonte green--text text-uppercase font-weight-light">{{ getPurchase.transaction.transaction_type }}</span>

            <span class="grey--text fonte mt-6">STATUS DO PAGAMENTO:</span>
            <span class="fonte green--text font-weight-light">{{ getPurchase.current_status | current_status_filter }}</span>
            
            <span class="grey--text fonte mt-6">LINKS:</span>
            <SendArchive />
          </div>
        </v-flex>

        <v-flex xs12 md4 class="pa-3 pt-6">
          <div class="expande-horizontal bordered column pa-3">
            <h1 class="fonte white--text text-center mb-3">Movimentações:</h1>
            <v-divider dark class="mb-3"></v-divider>
            <v-timeline align-top dark>
              <v-timeline-item dark :color="status.color" small :key="status.status" v-for="status in getPurchase.status">
                  <span class="fonte white--text"> {{ status.status }}</span>
                  <span class="grey--text fonte"> ({{ $moment(status.date).format('DD/MM/YYYY [às] HH:mm') }}) </span>
              </v-timeline-item>
            </v-timeline>
            <v-text-field
              solo-inverted
              dark
              v-model="new_status"
              flat
              rounded
              placeholder="Informar novo Status"
              append-icon="mdi-send"
              :color="$theme.primary"
              @click:append="updateStatus({ status: new_status, _id: id })"
              @keyup.enter="updateStatus({ status: new_status, _id: id })"
            ></v-text-field>
          </div>
        </v-flex>
      </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PurchaseProduct from "./PurchaseProduct";
import PurchaseCareer from "./PurchaseCareer";
import PurchaseInvestment from "./PurchaseInvestment";
import SendArchive from '@/apps/shared/components/ModalSendArchive'
export default {
  props: ["id"],
  data() {
    return {
      new_status: "",
      options: [
        {
          nome: 'APROVAR COMPRA',
          action: () => this.validatePurchase()
        }
      ]
    }
  },
  components: {
    PurchaseProduct,
    PurchaseCareer,
    PurchaseInvestment,
    SendArchive
  },
  filters: {
    current_status_filter(val) {
      const range = {
        'active': 'PAGO',
        'aguardando': 'AGUARDANDO...',
        'ativando': 'APROVADO - AGUARDANDO ATIVAÇÃO...',

      }
      return range[val]
    }
  },
  computed: {
    ...mapGetters(["getModalViewPurchase", "getPurchase", "getPaymentMethods", "getPaymentMethod", "getAddresses", "getLinks"])
  },
  methods: {
    ...mapActions(["updateStatus","viewPurchases", "cancelPurchase", "listPaymentMethods", "listAddresses", "enableModalSendArchive","createGlobalMessage", "createConfirmAction", "openModalViewAddress"]),
    validatePurchase() {
      if(this.getPurchase.current_status === 'active') {
        this.createGlobalMessage({
          opened: true,
          type: 'success',
          message: 'A compra já está aprovada',
          timeout: 5000
        })
      } else {
        this.createConfirmAction({
          opened: true,
          icon: "mdi-check",
          color: "green",
          message: "Confirma a aprovação da compra?",
          action: "aprrovePurchase",
          action_value: this.getPurchase
        })
      }
    },
    goToUser() {
      this.$router.push('/view-user/'+this.getPurchase.user_id)
    }
  },
  created() {
    this.viewPurchases({ _id: this.id });
    // this.listPaymentMethods();
    // this.listAddresses();
  }
};
</script>

<style>
  .bordered {
    border: 1px solid #5e5e5e;
    border-radius: 6px;;
  }
</style>
